
const config: IConfig = {
  serve: 'http://rap2.ictergel.top/rap2Server',
  // serve: 'http://192.168.159.128:18080/rap2Server',
  // serve: 'http://192.168.202.28:18080/rap2Server',
  keys: ['some secret hurr'],
  session: {
    key: 'koa:sess',
  },
}

export default config
